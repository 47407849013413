<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="all"
      :footer-props="{
        itemsPerPageText: 'Filas por página',
        itemsPerPageOptions: [10,30,90]
      }"
      :loading="loading"
      :options.sync="options"
      :server-items-length="itemsLength"
      :items-per-page="15"
      disable-sort
    >
      <template v-slot:item.id="{ item }">
        <div v-if="item.id">
          <v-btn
            icon
          >
            <v-icon @click="">mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:item.retirado="{ item }">
        <div v-if="item.retirado">
          {{ item.retirado | moment('MM/YYYY') }}
        </div>
      </template>
      <template v-slot:item.recipiente.fabricante="{ item }">
        <div v-if="item.recipiente.fabricante">
          {{ item.recipiente.fabricante.nombre }}
        </div>
      </template>
      <template v-slot:item.recipiente.tipo="{ item }">
        <div v-if="item.recipiente.tipo">
          {{ item.recipiente.tipo.abreviatura }}
        </div>
      </template>
      <template v-slot:item.recipiente.vencimiento_ph="{ item }">
        <div v-if="item.recipiente.vencimiento_ph">
          {{ item.recipiente.vencimiento_ph | moment('MM/YYYY') }}
        </div>
      </template>
      <template v-slot:item.recipiente.aprobado_ph="{ item }">
        <div v-if="item.recipiente.aprobado_ph">
          {{ item.recipiente.aprobado_ph | moment('MM/YYYY') }}
        </div>
      </template>
      <template v-slot:item.recipiente.fabricacion="{ item }">
        <div v-if="item.recipiente.fabricacion">
          <v-chip @click="">{{ item.recipiente.fabricacion }}</v-chip>
        </div>
      </template>
      <template v-slot:item.rehabilitacion="{ item }">
        <div v-if="item.rehabilitacion">
          <v-chip @click="">{{ item.rehabilitacion }}</v-chip>
        </div>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ search }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="warning" icon="mdi-alert">
          No hay información de recipientes para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DatePicker from '@/components/DatePicker'
import FabricanteSelect from '@/pages/fabricantes/Select'
export default {
  props: {
    comodatoId: {
      type: Number,
      default: null
    },
    update: {
      type: Boolean,
      default: false
    },
  },
  components: {
    'my-date-picker': DatePicker,
    'my-fabricante-select': FabricanteSelect,
  },
  data () {
    return {
      //
    }
  },
  created () {
    this.onClearFilters()
  },
  computed: {
    ...mapGetters('ComodatosRecipientes', ['all', 'headers', 'loading', 'itemsLength', 'filters']),
     options: {
      get () {
        return this.optionsGetter
      },
      set (value) {
        this.setOptions(value)
        if (this.filters.comodato_id) this.onFetch()
      }
    },
  },
  watch: {
    options (value) {
      if (this.filters.comodato_id) this.onFetch()
    },
    comodatoId (value) {
      this.setFilters({
        ...this.filters,
        comodato_id: value
      })
      if (this.filters.comodato_id) this.onFetch()
    },
    update (value) {
      if (value) this.onFetch()
    }
  },
  methods: {
    ...mapActions('ComodatosRecipientes', ['onFetch', 'onClearFilters']),
    ...mapMutations('ComodatosRecipientes', ['setOptions', 'setPage', 'setFilters']),
  }
}
</script>
