<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"      
      >
        <v-container>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                :value="numero"
                prepend-icon="mdi-identifier"
                label="Identificación"
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-list-item
                link
              >
                <v-list-item-icon>
                  <v-icon>
                    {{ clienteId ? 'mdi-account-star' : 'mdi-account-plus' }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle v-if="clienteId">Cliente</v-list-item-subtitle>
                  <v-list-item-title v-else>Cliente</v-list-item-title>
                  <v-list-item-title v-if="clienteId" class="text-wrap">{{ cliente }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
              >
                <v-list-item-icon>
                  <v-icon v-if="domicilio">mdi-map-marker-star</v-icon>
                  <v-icon v-else>mdi-map-marker</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle v-if="domicilioId">Domicilio</v-list-item-subtitle>
                  <v-list-item-title v-else>Domicilio</v-list-item-title>
                  <v-list-item-title v-if="domicilioId" class="text-wrap">{{ domicilio }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12">
              <my-date-picker
                label="Fecha firmado"
                v-model="firmado"
                clearable
              ></my-date-picker>
            </v-col>
            <v-col cols="12">
              <my-date-picker
                label="Fecha vencimiento"
                v-model="vencimiento"
                clearable
              ></my-date-picker>
            </v-col>
          </v-row>
          <v-footer>
            <v-btn
              @click="onSubmit"
              block
              color="primary"
            >
              Registrar
            </v-btn>
          </v-footer>
        </v-container>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DatePicker from '@/components/DatePicker'
export default {
  components: {
    'my-date-picker': DatePicker,
  },
  data () {
    return {
      valid: false,
    }
  },
  created () {
    // this.onClearEdited()
  },
  computed: {
    ...mapGetters('Comodatos',['edited', 'paneles', 'rules', 'loading']),
    ...mapGetters('Clientes',{ selectedCliente: 'selected' }),
    ...mapGetters('Domicilios',{ selectedDomicilio: 'selected' }),
    cliente () {
      return this.edited.cliente_id && this.selectedCliente ? this.selectedCliente.razon_social : null
    },
    domicilio () {
      return this.edited.cliente_id && this.selectedDomicilio ? this.selectedDomicilio.descripcion : null
    },
    id () {
      return this.edited.id
    },
    panelCliente: {
      get () {
        return this.paneles.cliente
      },
      set (value) {
        this.setPaneles({
          ...this.paneles,
          cliente: value
        })
      }
    },
    panelDomicilio: {
      get () {
        return this.paneles.domicilio
      },
      set (value) {
        this.setPaneles({
          ...this.paneles,
          domicilio: value
        })
      }
    },
    clienteId () {
      return this.edited.cliente_id
    },
    domicilioId () {
      return this.edited.domicilio_id
    },
    numero: {
      get () {
        return this.edited.numero
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          numero: value
        })
      }
    },
    firmado: {
      get () {
        return this.edited.firmado
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          firmado: value
        })
      }
    },
    vencimiento: {
      get () {
        return this.edited.vencimiento
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          vencimiento: value
        })
      }
    },
  },
  methods: {
    ...mapActions('Comodatos', ['onSave', 'onClearEdited']),
    ...mapMutations('Comodatos', ['setEdited']),
    onSubmit (event) {
      event.preventDefault()
      this.onSave().then(response => {
        //
      })
    },
  },
}
</script>
