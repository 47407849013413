<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <v-alert
            border="left"
            colored-border
            color="primary"
            icon="mdi-map-marker-plus"
          >
            Este formulario se utiliza para registrar un Domicilio Nuevo
          </v-alert>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="descripcion"
                placeholder="Descripcion"
                prepend-icon="mdi-text"
                :rules="rules.descripcion"
                label="Descripción"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="observaciones"
                placeholder="Observaciones"
                prepend-icon="mdi-text"
                :rules="rules.observaciones"
                label="Observaciones del domicilio"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <my-zona-select
                v-model="zona"
                :rules="rules.zona_id"
              ></my-zona-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="posicion"
                prepend-icon="mdi-text"
                :rules="rules.posicion"
                label="Posición"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="latitud"
                placeholder="Ingrese una latitud"
                prepend-icon="mdi-text"
                label="Latitud"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="longitud"
                placeholder="Ingrese una longitud"
                prepend-icon="mdi-text"
                label="Longitud"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-footer>
          <v-btn
            color="primary"
            @click="onSubmit"
            :loading="loading"
          >
            <v-icon left>mdi-keyboard-f4</v-icon>
            Registrar
            <v-icon right>mdi-check</v-icon>
          </v-btn>          
        </v-footer>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ZonaSelect from '@/pages/zonas/Select'
export default {
  components: {
    'my-zona-select': ZonaSelect
  },
  props: {
    clienteId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      valid: false,
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    // this.onDefaults()
    // if (this.$route.params.id)
    //   this.cliente = this.$route.params.id
  },
  computed: {
    ...mapGetters('Domicilios', ['edited', 'rules', 'loading', 'filters']),
    id () {
      return this.edited.id
    },
    descripcion: {
      get () {
        return this.edited.descripcion
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          descripcion: value
        })
      }
    },
    posicion: {
      get () {
        return this.edited.posicion
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          posicion: value
        })
      }
    },
    latitud: {
      get () {
        return this.edited.latitud
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          latitud: value
        })
      }
    },
    longitud: {
      get () {
        return this.edited.longitud
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          longitud: value
        })
      }
    },
    observaciones: {
      get () {
        return this.edited.observaciones
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          observaciones: value
        })
      }
    },
    cliente: {
      get () {
        return this.edited.cliente_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          cliente_id: value
        })
      }
    },
    zona: {
      get () {
        return this.edited.zona_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          zona_id: value
        })
      }
    },
  },
  watch: {
    clienteId (value) {
      this.setEdited({
        ...this.edited,
        cliente_id: value
      })
    }
  },
  methods: {
    ...mapActions('Domicilios', ['onFetch', 'onSave', 'onClearEdited']),
    ...mapMutations('Domicilios', ['setEdited']),
    onSubmit () {
      this.$refs.form.validate()
      if (this.valid) {
        //
        this.onSave().then(() => {
          this.onDefault()
          //
        })
      }
    },
    onDefault () {
      this.onFetch()
      this.onClearEdited()
      this.setEdited({
        ...this.edited,
        cliente_id: this.clienteId
      })
      this.$refs.form.reset()
    },
    handleKeyboard(event) {
      if(event.key === "F4") {
        event.preventDefault()
        this.onSubmit()
      }
    }
  },
}
</script>
