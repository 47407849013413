<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <v-subheader>Certificado de fabricante</v-subheader>
          <v-text-field
            v-model="identificacion"
            label="Número de certificado"
            :rules="rules.identificacion"
            prepend-icon="mdi-identifier"
            color="primary"
          ></v-text-field>
          <my-date-picker
            v-model="emision"
            label="Fecha de emisión"
            :rules="rules.emision"
          ></my-date-picker>
          <my-proveedor-select
            v-model="proveedor"
            :rules="rules.proveedor_id"
          ></my-proveedor-select>
          <v-text-field
            v-model="norma"
            placeholder="Ingrese una descripción"
            label="Norma de fabricación"
            :rules="rules.norma"
            prepend-icon="mdi-text-box"
            color="primary"
          ></v-text-field>
          <v-text-field
            v-model="plano"
            placeholder="p. ej.: Z-8899 Rev. 9"
            label="Modelo aprobado según plano"
            :rules="rules.plano"
            prepend-icon="mdi-map-outline"
            color="primary"
          ></v-text-field>
          <v-text-field
            v-model="matricula"
            placeholder="Ingrese un número de matrícula"
            label="Matrícula"
            :rules="rules.matricula"
            prepend-icon="mdi-pound"
            color="primary"
          ></v-text-field>
          <!-- Agregar un campo para cargar el archivo -->
          <!--
          <v-file-input
            accept="*"
            label="Documento Escaneado"
            v-model="adjunto"
            ref="adjuntoInput"
          ></v-file-input>
          <v-file-input
            accept="image/*"
            label="Documento Escaneado"
          ></v-file-input>
          -->
          <my-file-upload
            @uploaded="onUploaded"
            :url="adjunto"
            label="Documento escaneado"
          ></my-file-upload>
        </v-container>
        <v-footer>
          <v-btn
            color="primary"
            @click="onValidate"
          >
            Registrar
          </v-btn>            
        </v-footer>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DatePicker from '@/components/DatePicker'
import FileUpload from '@/components/FileUpload'
import ProveedorSelect from '@/pages/proveedores/Select'
export default {
  components: {
    'my-date-picker': DatePicker,
    'my-file-upload': FileUpload,
    'my-proveedor-select': ProveedorSelect
  },
  data () {
    return {
      valid: true,
      adjuntoUpload: null,
    }
  },
  created () {
    // this.onClearEdited()
  },
  computed: {
    ...mapGetters('Fabricaciones', {
      edited: 'edited',
      rules: 'rules',
      loading: 'loading'
    }),
    emision: {
      get () {
        return this.edited.emision
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          emision: value
        })
      }
    },
    matricula: {
      get () {
        return this.edited.matricula
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          matricula: value
        })
      }
    },
    identificacion: {
      get () {
        return this.edited.identificacion
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          identificacion: value
        })
      }
    },
    norma: {
      get () {
        return this.edited.norma
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          norma: value
        })
      }
    },
    plano: {
      get () {
        return this.edited.plano
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          plano: value
        })
      }
    },
    proveedor: {
      get () {
        return this.edited.proveedor_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          proveedor_id: value
        })
      }
    },
    adjunto: {
      get () {
        return this.edited.adjunto
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          adjunto: value
        })
      }
    },
  },
  watch: {
    adjunto (value) {
      alert(value)
    }
  },
  methods: {
    ...mapActions('Fabricaciones', ['onSave', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Fabricaciones', ['setEdited']),
    onUploaded (value) {
      this.adjunto = value
      console.log(JSON.stringify(this.edited))
    },
    onValidate (event) {
      event.preventDefault()
      this.$refs.form.validate()
      if (this.valid) {
        this.onSave().then(() => {
          // this.$router.push({ name: 'recipientes-main' })
        })
      }
    },
  },
}
</script>
