<template>
  <div>
    <v-expansion-panels v-model="panelOpen">
      <v-expansion-panel>
        <v-expansion-panel-header color="searchPanel">Recipientes disponibles para agregar al comodato</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="headers"
            :items="all"
            :footer-props="{
              itemsPerPageText: 'Filas por página',
              itemsPerPageOptions: [5, 15]
            }"
            :loading="loading"
            :options.sync="optionsLocal"
            :server-items-length="itemsLength"
            :items-per-page="5"
            disable-sort
            @click:row="onSelect"
          >
            <template v-slot:top>
              <v-text-field
                v-model="searchText"
                placeholder="Escriba algún dato del recipiente"
                @keydown.enter="onSubmit"
                hint="Enter para buscar recipiente"
              >
                <template v-slot:prepend>
                  <v-btn
                    icon
                    @click="onSubmit"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </template>
            <template v-slot:item.fabricante="{ item }">
              <div v-if="item.fabricante">
                {{ item.fabricante.nombre }}
              </div>
            </template>
            <template v-slot:item.tipo="{ item }">
              <div v-if="item.tipo">
                {{ item.tipo.abreviatura }}
              </div>
            </template>
            <template v-slot:item.vencimiento_ph="{ item }">
              <div v-if="item.vencimiento_ph">
                {{ item.vencimiento_ph | moment('MM/YYYY') }}
              </div>
            </template>
            <template v-slot:item.aprobado_ph="{ item }">
              <div v-if="item.aprobado_ph">
                {{ item.aprobado_ph | moment('MM/YYYY') }}
              </div>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="mdi-alert">
                No se ha encontrado ningún resultado para "{{ search }}".
              </v-alert>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="warning" icon="mdi-alert">
                No hay información de recipientes para mostrar.
              </v-alert>
            </template>
            <template v-slot:pageText="props">
              Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DatePicker from '@/components/DatePicker'
import FabricanteSelect from '@/pages/fabricantes/Select'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      type: Number,
      default: null
    },
    comodatoId: {
      type: Number,
      default: null
    },
    update: {
      type: Boolean,
      default: false
    },
  },
  components: {
    'my-date-picker': DatePicker,
    'my-fabricante-select': FabricanteSelect,
  },
  data () {
    return {
      panelOpen: undefined, // Inicialmente cerrado
    }
  },
  created () {
    this.onClearFilters()
    const comodatoId = this.$route.params.id
    if (comodatoId) {
      this.comodato = comodatoId
      console.log(comodatoId)
    }
  },
  computed: {
    ...mapGetters('RecipientesSelect', ['all', 'headers', 'loading', 'itemsLength', 'options', 'filters']),
     optionsLocal: {
      get () {
        return this.options
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
    searchText: {
      get () {
        return this.filters.searchText
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          searchText: value
        })
      }
    },
    comodato: {
      get () {
        return this.filters.comodato_id
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          comodato_id: value
        })
      }
    }
  },
  watch: {
    optionsLocal (value) {
      // if (this.filters.comodato_id) this.onFetch()
      // this.onFetch()
    },
    comodatoId (value) {
      // this.comodato = value
      // this.onFetch()
    },
    update (value) {
      if (value) this.onFetch()
    }
  },
  methods: {
    ...mapActions('RecipientesSelect', ['onFetch', 'onClearFilters']),
    ...mapMutations('RecipientesSelect', ['setOptions', 'setPage', 'setFilters', 'setAll']),
    onSelect (item) {
      // Envio el id del recipiente al componente padre
      if (confirm("¿Desea asociar el recipiente " + item.numero + "?")) {
        // El usuario hizo clic en "Aceptar"
        this.$emit('change', item.id)
        // Para finalizar, se limpian las variables
        this.panelOpen = undefined
        this.setAll([])
        this.searchText = null
      } else {
        // El usuario hizo clic en "Cancelar"
        // Aquí puedes poner el código que quieres que se ejecute si el usuario cancela
        console.log("El usuario ha cancelado.");
      }
    },
    onSubmit (event) {
      event.preventDefault()
      this.onFetch()
    }
  }
}
</script>
