const URL = '/recipientes';

const state = {
  all: [],
  found: [],
  selected: {
    id: null,
    numero: null,
    numero_hasta: null,
    capacidad: null,
    volumen: null,
    aprobado_ph: null,
    vencimiento_ph: null,
    presion_disenio: null,
    presion_ph: null,
    recipiente_tipo_id: null,
    certificado_fabricante_id: null,
  },
  filters: {
    searchText: null,
    numero: null,
    numero_hasta: null,
    capacidad: null,
    volumen: null,
    aprobado_from: null,
    aprobado_to: null,
    vencimiento_from: null,
    vencimiento_to: null,
    presion_disenio: null,
    presion_ph: null,
    recipiente_tipo_id: null,
    certificado_fabricante_id: null,
    fabricante_id: null,
    certificado_identificacion: null,
    excluir: {
      certificado_fabricante_id: null,
      certificado_rehabilitante_id: null,
    }
  },
  edited: {
    id: null,
    numero: null,
    numero_hasta: null,
    capacidad: null,
    volumen: null,
    aprobado_ph: null,
    vencimiento_ph: null,
    presion_disenio: null,
    presion_ph: null,
    recipiente_tipo_id: null,
    certificado_fabricante_id: null,
  },
  default: {
    id: null,
    numero: null,
    numero_hasta: null,
    capacidad: null,
    volumen: null,
    aprobado_ph: null,
    vencimiento_ph: null,
    presion_disenio: null,
    presion_ph: null,
    recipiente_tipo_id: null,
    certificado_fabricante_id: null,
  },
  loading: false,
  headers: [
    {
      text: 'Matricula',
      value: 'numero',
      align: 'center'
    },
    {
      text: 'Fabricante',
      value: 'fabricante',
      align: 'center'
    },
    {
      text: 'Tipo',
      value: 'tipo',
      align: 'center'
    },
    {
      text: 'Capacidad (m3)',
      value: 'capacidad',
      align: 'center'
    },
    {
      text: 'Volumen (Lts)',
      value: 'volumen',
      align: 'center'
    },
    {
      text: 'Presión diseño (Kg/cm2)',
      value: 'presion_disenio',
      align: 'center'
    },
    {
      text: 'Presión PH (Kg/cm2)',
      value: 'presion_ph',
      align: 'center'
    },
    {
      text: 'Fecha PH',
      value: 'aprobado_ph',
      align: 'center'
    },
    {
      text: 'Vencimiento PH',
      value: 'vencimiento_ph',
      align: 'center'
    },
    {
      text: 'Fabricacion',
      value: 'fabricacion',
      align: 'center'
    },
    {
      text: 'Rehabilitacion',
      value: 'rehabilitacion',
      align: 'center'
    },
  ],
  validations: {
    numero: [
      v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Este campo debe ser mayor a cero',
    ],
    numero_hasta: [
      v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Este campo debe ser mayor a cero',
    ],
    capacidad: [
      v => !!v || 'Este campo es necesario',
    ],
    volumen: [
      v => !!v || 'Este campo es necesario',
    ],
    aprobado_ph: [
      v => !!v || 'Este campo es necesario',
    ],
    vencimiento_ph: [
      v => !!v || 'Este campo es necesario',
    ],
    presion_disenio: [
      v => !!v || 'Este campo es necesario',
    ],
    presion_ph: [
      v => !!v || 'Este campo es necesario',
    ],
    recipiente_tipo_id: [
      v => !!v || 'Este campo es necesario',
      v => _.isInteger(v) || 'Debe existir un Tipo de Recipiente seleccionado',
    ],
    certificado_fabricante_id: [
      v => !!v || 'Este campo es necesario',
      v => _.isInteger(v) || 'Debe existir un Tipo de Instalacion seleccionado',
    ],
  },
  options: {},
  itemsLength: 5,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  anySelectedFilters (state) {
    return state.filters.recipiente_tipo_id ||
          state.filters.numero ||
          state.filters.capacidad ||
          state.filters.volumen ||
          state.filters.aprobado_from ||
          state.filters.aprobado_to ||
          state.filters.vencimiento_from ||
          state.filters.vencimiento_to || 
          state.filters.presion_disenio || 
          state.filters.presion_ph || 
          state.filters.fabricante_id ? false : true;
  },
  updatable (state) {
    return state.edited.id == state.selected.id && (
            state.edited.recipiente_tipo_id != state.selected.recipiente_tipo_id ||
            state.edited.numero != state.selected.numero ||
            state.edited.capacidad != state.selected.capacidad ||
            state.edited.volumen != state.selected.volumen ||
            state.edited.aprobado != state.selected.aprobado ||
            state.edited.vencimiento != state.selected.vencimiento ||
            state.edited.presion_disenio != state.selected.presion_disenio ||
            state.edited.presion_ph != state.selected.presion_ph ||
            state.edited.fabricante_id != state.selected.fabricante_id
          )
  },
  found (state) {
    return state.found
  },
  edited (state) {
    return state.edited
  },
  filters (state) {
    return state.filters
  },
  selected (state) {
    return state.selected
  },
  rules (state) {
    return state.validations
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  loading (state) {
    return state.loading
  },
}

// ACTIONS
const actions = {
  onClearSelected ({ state, commit }) {
    commit('setSelected', state.default)
  },
  onClearEdited ({ state, commit }) {
    commit('setEdited', state.default)
  },
  onClearFilters ({ state, commit }) {
    commit('setFilters', {
      ...state.filters,
      searchText: null,
      numero: null,
      capacidad: null,
      volumen: null,
      aprobado: {
        from: null,
        to: null
      },
      vencimiento: {
        from: null,
        to: null
      },
      presion_disenio: null,
      presion_ph: null,
      recipiente_tipo_id: null,
      certificado_fabricante_id: null,
      fabricante_id: null,
      excluir: {
        certificado_fabricante_id: null,
        certificado_rehabilitante_id: null,
      }
    })
  },
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL, {
          params: {
            itemsPerPage: state.options.itemsPerPage,
            page: state.options.page,
            sortBy: state.options.sortBy[0] || '',
            sortDesc: state.options.sortDesc[0] ? 1 : 0,
            numero: state.filters.numero,
            capacidad: state.filters.capacidad,
            volumen: state.filters.volumen,
            aprobado_from: state.filters.aprobado_from,
            aprobado_to: state.filters.aprobado_to,
            vencimiento_from: state.filters.vencimiento_from,
            vencimiento_to: state.filters.vencimiento_to,
            presion_disenio: state.filters.presion_disenio,
            presion_ph: state.filters.presion_ph,
            recipiente_tipo_id: state.filters.recipiente_tipo_id,
            certificado_fabricante_id: state.filters.certificado_fabricante_id,
            fabricante_id: state.filters.fabricante_id,
            certificado_identificacion: state.filters.certificado_identificacion,
            excluir_certificado_fabricante_id: state.filters.excluir.certificado_fabricante_id,
            excluir_certificado_rehabilitante_id: state.filters.excluir.certificado_rehabilitante_id
          }
        })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          commit('setAll', [])
          commit('setItemsLength', 0)
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onFind ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL + '/' + payload)
        .then(response => {
          commit('setEdited', response.data)
          commit('setSelected', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSearch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL, {
        params: {
          itemsPerPage: state.options.itemsPerPage,
          page: state.options.page,
          search: state.filters.searchText,
          numero: state.filters.numero,
          capacidad: state.filters.capacidad,
          volumen: state.filters.volumen,
          aprobado_from: state.filters.aprobado.from,
          aprobado_to: state.filters.aprobado.to,
          vencimiento_from: state.filters.vencimiento.from,
          vencimiento_to: state.filters.vencimiento.to,
          presion_disenio: state.filters.presion_disenio,
          presion_ph: state.filters.presion_ph,
          recipiente_tipo_id: state.filters.recipiente_tipo_id,
          certificado_fabricante_id: state.filters.certificado_fabricante_id,
          sortBy: state.options.sortBy && state.options.sortBy[0] || '',
          sortDesc: state.options.sortDesc && state.options.sortDesc[0] ? 1 : 0,
        }
      })
        .then(response => {
          commit('setFound', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL, state.edited)
        .then(response => {
          commit('setEdited', response.data.data)
          commit('setSelected', response.data.data)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el recipiente', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el recipiente', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setId', response.data.id)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado los cambios en el registro del recipiente', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar cambios en el registro recipiente', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Se ha borrado el recipiente', { root: true })
          commit('setEdited', state.default)
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al borrar el recipiente', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setFound (state, payload) {
    state.found = payload
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
