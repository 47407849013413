<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="all"
      :footer-props="{
        itemsPerPageText: 'Filas por página',
        itemsPerPageOptions: [10, 30, 90]
      }"
      :loading="loading"
      :options.sync="options"
      :server-items-length="itemsLength"
      :items-per-page="10"
      disable-sort
    >
      <template v-slot:item.id="{ item }">
        <div v-if="item.id">
          <v-btn
            icon
            color="error"
            @click="onSubmitDelete(item.id)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:item.recipiente.fabricante="{ item }">
        <div v-if="item.recipiente.fabricante">
          {{ item.recipiente.fabricante.nombre }}
        </div>
      </template>
      <template v-slot:item.recipiente.tipo="{ item }">
        <div v-if="item.recipiente.tipo">
          {{ item.recipiente.tipo.abreviatura }}
        </div>
      </template>
      <template v-slot:item.recipiente.vencimiento_ph="{ item }">
        <div v-if="item.recipiente.vencimiento_ph">
          {{ item.recipiente.vencimiento_ph | moment('DD/MM/YYYY') }}
        </div>
      </template>
      <template v-slot:item.recipiente.aprobado_ph="{ item }">
        <div v-if="item.recipiente.aprobado_ph">
          {{ item.recipiente.aprobado_ph | moment('DD/MM/YYYY') }}
        </div>
      </template>
      <template v-slot:no-results>
        <v-alert color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ search }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert
          v-if="!certificadoRehabilitanteId"
          color="warning"
          border="left"
          colored-border
          icon="mdi-alert"
        >
          No hay Certificado Rehabilitante seleccionado. No se puede agregar recipientes
        </v-alert>
        <v-alert
          v-else
          color="warning"
          icon="mdi-alert"
        >
          Actualmente no existen recipientes asociados.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DatePicker from '@/components/DatePicker'
import FabricanteSelect from '@/pages/fabricantes/Select'
export default {
  components: {
    'my-date-picker': DatePicker,
    'my-fabricante-select': FabricanteSelect,
  },
  props: {
    certificadoRehabilitanteId: {
      type: Number,
      default: null
    },
    certificadoFabricanteId: {
      type: Number,
      default: null
    },
  },
  data () {
    return {
      //
    }
  },
  created () {
    // if (this.certificadoRehabilitanteId) {
    //   this.setFilters({
    //     ...this.filters,
    //     certificado_rehabilitante_id: certificadoRehabilitanteId
    //   })
    //   this.onFetch()
    // }
  },
  computed: {
    ...mapGetters('RehabilitacionesRecipientes', ['all', 'filters', 'headers', 'loading', 'itemsLength', 'options']),
    sortBy: {
      get () {
        return this.sort.by
      },
      set (value) {
        this.setSortBy(value)
        if (this.certificadoRehabilitanteId || this.certificadoFabricanteId) {
          this.onFetch()
        }
      }
    },
    sortDesc: {
      get () {
        return this.sort.desc
      },
      set (value) {
        this.setSortDesc(value)
        if (this.certificadoRehabilitanteId || this.certificadoFabricanteId) {
          this.onFetch()
        }
      }
    },
    options: {
      get () {
        return this.optionsGetter
      },
      set (value) {
        this.setOptions(value)
        if (this.certificadoRehabilitanteId || this.certificadoFabricanteId) {
          this.onFetch()
        }
      }
    },
  },
  watch: {
    certificadoRehabilitanteId (value) {
      this.setFilters({
        ...this.filters,
        certificado_rehabilitante_id: value,
        certificado_fabricante_id: null,
      })
      this.onFetch()
    },
    certificadoFabricanteId (value) {
      this.setFilters({
        ...this.filters,
        certificado_rehabilitante_id: null,
        certificado_fabricante_id: value
      })
      this.onFetch()
    }
  },
  methods: {
    ...mapActions('RehabilitacionesRecipientes', ['onFetch', 'onClearFilters', 'onDelete']),
    ...mapMutations('RehabilitacionesRecipientes', ['setOptions', 'setPage', 'setEdited', 'setSelected', 'setFilters', 'setSortBy', 'setSortDesc']),
    onSubmitDelete (valueId) {
      this.setEdited({
        ...this.edited,
        id: valueId
      })
      this.onDelete()
        .then(response => {
          this.onFetch()
        })
    }
  }
}
</script>
