<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"      
      >
        <v-container>
          <v-subheader v-show="subheader">{{ subheader }}</v-subheader>
          <v-alert
            v-if="updatable"
            border="left"
            colored-border
            color="warning"
            icon="mdi-alert"
          >
            Para guardar los cambios realizados, es necesario Actualizar
          </v-alert>
          <v-text-field
            v-model="razonSocial"
            placeholder="Ingrese apellido y nombre"
            label="Apellido y nombre"
            :rules="rules.razon_social"
            prepend-icon="mdi-text"
            color="primary"
          ></v-text-field>
          <v-text-field
            v-model="documento"
            placeholder="Ingrese un número de documento"
            label="Número de documento"
            :rules="rules.documento"
            prepend-icon="mdi-card-account-details"
            color="primary"
          ></v-text-field>
          <!--
          <v-text-field
            v-model="apellido"
            placeholder="Ingrese un apellido"
            prepend-icon="mdi-text"
            label="Apellido"
            :rules="rules.apellido"
            color="primary"
            clearable
          ></v-text-field>
          -->
          <!--
          <v-text-field
            v-model="nombre"
            placeholder="Ingrese un nombre"
            :rules="rules.nombre"
            prepend-icon="mdi-text"
            label="Nombre"
            color="primary"
            clearable
          ></v-text-field>
          -->
          <v-text-field
            v-model="email"
            placeholder="Ingrese un correo electrónico"
            label="Email"
            :rules="rules.email"
            prepend-icon="mdi-email"
            color="primary"
            clearable
          ></v-text-field>
          <v-text-field
            v-model="telefonoUno"
            placeholder="Ingrese un teléfono"
            label="Telefono 1"
            :rules="rules.telefono_uno"
            prepend-icon="mdi-phone"
            color="primary"
          ></v-text-field>
          <v-text-field
            v-model="telefonoDos"
            placeholder="Ingrese un teléfono"
            label="Telefono 2"
            :rules="rules.telefono_dos"
            prepend-icon="mdi-phone"
            color="primary"
          ></v-text-field>
          <v-switch
            v-model="inactivo"
            label="Inactivo"
            color="primary"
          ></v-switch>
        </v-container>
        <v-footer>
          <v-btn
            :disabled="!updatable"
            color="primary"
            @click="onSubmit"
            :loading="loading"
          >
            Actualizar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-show="mostrarClienteNuevo"
            color="primary"
            @click="onClienteNuevo"
          >
            Cliente Nuevo
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-footer>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  props: {
    mostrarClienteNuevo: {
      type: Boolean,
      default: false
    },
    subheader: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      valid: false,
    }
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Clientes', ['edited', 'rules', 'loading', 'updatable']),
    id () {
      return this.edited.id
    },
    documento: {
      get () {
        return this.edited.documento
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          documento: value
        })
      }
    },
    razonSocial: {
      get () {
        return this.edited.razon_social
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          razon_social: value
        })
      }
    },
    apellido: {
      get () {
        return this.edited.apellido
      },
      set (value) {        
        this.setEdited({
          ...this.edited,
          apellido: value
        })
      }
    },
    nombre: {
      get () {
        return this.edited.nombre
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          nombre: value
        })
      }
    },
    email: {
      get () {
        return this.edited.email
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          email: value
        })
      }
    },
    telefonoUno: {
      get () {
        return this.edited.telefono_uno
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          telefono_uno: value
        })
      }
    },
    telefonoDos: {
      get () {
        return this.edited.telefono_dos
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          telefono_dos: value
        })
      }
    },
    // inactivo: {
    //   get () {
    //     return !this.edited.inactivo ? false : true
    //   },
    //   set (value) {
    //     this.setEdited({
    //       ...this.edited,
    //       inactivo: new Date()
    //     })
    //   }
    // },
    inactivo: {
      get () {
        return this.edited.inactivo ? true : false
      },
      set (value) {
        const now = new Date()
        var inactivoAux = null
        if (value === true) inactivoAux = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.setEdited({
          ...this.edited,
          inactivo: inactivoAux
        })
      }
    },
  },
  methods: {
    ...mapActions('Clientes', ['onUpdate', 'onFind', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Clientes', ['setEdited']),
    onSubmit () {
      this.$refs.form.validate()
      if (this.valid) {
        this.onUpdate().then(response => {
          //
          this.$emit('updated', this.edited.id)
        })
      }
    },
    onClienteNuevo () {
      this.onClearEdited()
      this.onClearSelected()
      this.$emit('clear', null)
    }
  },
}
</script>
