<template>
  <div>
    <v-expansion-panels v-model="panelOpen">
      <v-expansion-panel>
        <v-expansion-panel-header color="searchPanel">Recipientes disponibles para agregar al certificado</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="headers"
            :items="all"
            :footer-props="{
              itemsPerPageText: 'Filas por página',
              itemsPerPageOptions: [5, 15]
            }"
            :loading="loading"
            :options.sync="optionsLocal"
            :server-items-length="itemsLength"
            :items-per-page="5"
            disable-sort
            @click:row="onSelect"
          >
            <template v-slot:top>
              <slot name="top"></slot>
              <v-text-field
                v-model="searchText"
                placeholder="Escriba algún dato del recipiente"
                @keydown.enter="onSubmit"
                hint="Enter para buscar recipiente"
              >
                <template v-slot:prepend>
                  <v-btn
                    icon
                    @click="onSubmit"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </template>
            <template v-slot:item.fabricante="{ item }">
              <div v-if="item.fabricante">
                {{ item.fabricante.nombre }}
              </div>
            </template>
            <template v-slot:item.tipo="{ item }">
              <div v-if="item.tipo">
                {{ item.tipo.abreviatura }}
              </div>
            </template>
            <template v-slot:item.vencimiento_ph="{ item }">
              <div v-if="item.vencimiento_ph">
                {{ item.vencimiento_ph | moment('MM/YYYY') }}
              </div>
            </template>
            <template v-slot:item.aprobado_ph="{ item }">
              <div v-if="item.aprobado_ph">
                {{ item.aprobado_ph | moment('MM/YYYY') }}
              </div>
            </template>
            <template v-slot:no-results>
              <v-alert color="error" icon="mdi-alert">
                No se ha encontrado ningún resultado para "{{ search }}".
              </v-alert>
            </template>
            <template v-slot:no-data>
              <v-alert color="warning" icon="mdi-alert">
                No hay información de recipientes para mostrar.
              </v-alert>
            </template>
            <template v-slot:pageText="props">
              Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      type: Number,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rules: Array,
  },
  data () {
    return {
      panelOpen: undefined, // Inicialmente cerrado
    }
  },
  created () {
    this.onClearFilters()
    const certificadoRehabilitanteId = this.$route.params.id
    if (certificadoRehabilitanteId) {
      this.setFilters({
        ...this.filters,
        excluir: {
          certificado_fabricante_id: null,
          certificado_rehabilitante_id: certificadoRehabilitanteId
        }
      })
      console.log(certificadoRehabilitanteId)
    }
    // if () {
    //   this.setFilters({
    //     ...this.filters,
    //     excluir: {
    //       certificado_fabricante_id: null,
    //       certificado_rehabilitante_id: null
    //     }
    //   })
    // }
  },
  computed: {
    ...mapGetters('RecipientesSelect', ['all', 'headers', 'filters', 'loading', 'itemsLength', 'options']),
    optionsLocal: {
      get () {
        return this.options
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
    searchText: {
      get () {
        return this.filters.searchText
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          searchText: value
        })
      }
    },
    selectedLocal: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    }
  },
  watch: {
    selectedLocal (value) {
      //
    }
  },
  methods: {
    ...mapActions('RecipientesSelect', ['onFetch', 'onClearFilters', 'onClearSelected', 'onClearEdited']),
    ...mapMutations('RecipientesSelect', ['setEdited', 'setFilters', 'setOptions', 'setAll']),
    onSelect (item) {
      // Envio el id del recipiente al componente padre
      if (confirm("¿Desea asociar el recipiente " + item.numero + "?")) {
        // El usuario hizo clic en "Aceptar"
        this.$emit('change', item.id)
        // Para finalizar, se limpian las variables
        this.setFilters({
          ...this.filters,
          searchText: null
        })
        this.panelOpen = undefined
        this.setAll([])
      } else {
        // El usuario hizo clic en "Cancelar"
        // Aquí puedes poner el código que quieres que se ejecute si el usuario cancela
        console.log("El usuario ha cancelado.");
      }
    },
    onClearSelect () {
      this.onClearSelected()
      this.onClearEdited()
      this.$emit('change', null)
      this.$emit('selected', null)
      this.setFound([])
    },
    onSubmit (event) {
      event.preventDefault()
      this.onFetch()
    }
  }
}
</script>
