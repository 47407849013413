<template>
  <div>
    <v-sheet
      v-if="showFilters"
    >
      <v-toolbar flat>
        <v-toolbar-title>Filtros</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="onClearFilters"
          :disabled="anySelectedFilters"
          :loading="loading"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp" dark>mdi-filter-variant-remove</v-icon>
          <div class="hidden-xs-only">Limpiar filtros</div>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" md="3">
            <my-date-picker
              label="Firmado desde el"
              v-model="firmadoFrom"
              clearable
            ></my-date-picker>
            <my-date-picker
              label="Firmado hasta el"
              v-model="firmadoTo"
              clearable
            ></my-date-picker>
          </v-col>
          <v-col cols="12" md="3">
            <my-date-picker
              label="Vencimiento desde el"
              v-model="vencimientoFrom"
              clearable
            ></my-date-picker>
            <my-date-picker
              label="Vencimiento hasta el"
              v-model="vencimientoTo"
              clearable
            ></my-date-picker>
          </v-col>
          <v-col cols="12" md="3">
            <my-zona-select
              v-model="zona"
              clearable
            ></my-zona-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="cliente"
              label="Cliente"
              messages="Enter para buscar"
              clearable
              prepend-icon="mdi-account"
              @keydown.enter="onFetch"
              @click:clear="onClear"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="domicilio"
              label="Domicilio"
              messages="Enter para buscar"
              clearable
              prepend-icon="mdi-map-marker"
              @keydown.enter="onFetch"
              @click:clear="onClear"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <my-tipo-instalacion-select
              v-model="tipoInstalacion"
              clearable
            ></my-tipo-instalacion-select>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-divider
      v-if="showOrdering"
    ></v-divider>
    <v-sheet
      v-if="showOrdering"
    >
      <v-toolbar flat>
        <v-toolbar-title>Ordenamiento</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <v-chip-group column active-class="primary">
              <v-chip @click.stop="onOrdenamientoVencimiento(1)">Publicados recientemente</v-chip>
              <v-chip @click.stop="onOrdenamientoActualizados(1)">Actualizados recientemente</v-chip>
              <v-chip @click.stop="onOrdenamientoCreados(1)">Creados recientemente</v-chip>
              <v-chip @click.stop="onOrdenamientoVencimiento(0)">Primeros publicados</v-chip>
              <v-chip @click.stop="onOrdenamientoActualizados(0)">Primeras actualizaciones</v-chip>
              <v-chip @click.stop="onOrdenamientoCreados(0)">Primeros creados</v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-divider></v-divider>
    <v-navigation-drawer
      permanent
      app
    >
      <v-list
        nav
      >
        <v-divider></v-divider>
        <v-list-item
          v-if="$can('ordenes.store')"
          :to="{ name: 'comodatos-form' }"
          class="primary"
          dark
        >
          <v-list-item-avatar>F1</v-list-item-avatar>
          <v-list-item-title>Nuevo comodato</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          link
          :class="showFilters ? 'primary--text' : ''"
          @click.stop="showFilters = !showFilters"
        >
          <v-list-item-avatar>F3</v-list-item-avatar>
          <v-list-item-title>Filtros</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-filter-variant</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          link
          :class="showOrdering ? 'primary--text' : ''"
          @click.stop="showOrdering = !showOrdering">
          <v-list-item-avatar>F4</v-list-item-avatar>
          <v-list-item-title>Ordenamiento</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-sort-variant</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          v-if="$can('ordenes.index')"
          @click="onFetch"
          :loading="loading"
        >
          <v-list-item-avatar>F5</v-list-item-avatar>
          <v-list-item-title>Actualizar</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-refresh</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="collection"
      :search="search"
      single-select
      :show-select="false"
      :footer-props="{
        itemsPerPageText: 'Filas por página',
        itemsPerPageOptions: [10,15,30,50,100]
      }"
      :loading="loading"
      :options.sync="options"
      :server-items-length="itemsLength"
      :items-per-page="15"
      disable-sort
    >
      <template v-slot:item.id="{ item }">
        <div v-if="item.id">
          <v-icon @click="">mdi-text-box</v-icon>
        </div>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ search }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="warning" icon="mdi-alert">
          No hay información de comodatos para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import TipoInstalacionSelect from '@/pages/tipos_instalaciones/Select'
import DatePicker from '@/components/DatePicker'
import ZonaSelect from '@/pages/zonas/Select'
export default {
  components: {
    'my-tipo-instalacion-select': TipoInstalacionSelect,
    'my-date-picker': DatePicker,
    'my-zona-select': ZonaSelect,
  },
  data () {
    return {
      title: 'Ordenes',
      search: '',
      selected: [],
      showFilters: false,
      showOrdering: false,
      today: null,
      yesterday: null,
      lastYear: null,
      published: null,
    }
  },
  beforeMount() {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy() {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    this.today = this.$moment().format('YYYY-MM-DD')
    this.yesterday = this.$moment().subtract(1, 'days').format('YYYY-MM-DD')
    this.lastYear = this.$moment().subtract(1, 'years').format('YYYY')
  },
  computed: {
    ...mapGetters({
      collection: 'Comodatos/all',
      anySelectedFilters: 'Comodatos/anySelectedFilters',
      anySelectedOrdering: 'Comodatos/anySelectedOrdering',
      filters: 'Comodatos/filters',
      headers: 'Comodatos/headers',
      loading: 'Comodatos/loading',
      itemsLength: 'Comodatos/itemsLength',
      optionsGetter: 'Comodatos/options',
      sortList: 'Comodatos/sortList',
      sort: 'Comodatos/sort',
    }),
    sortBy: {
      get () {
        return this.sort.by
      },
      set (value) {
        this.setSortBy(value)
        this.onFetch()
      }
    },
    sortDesc: {
      get () {
        return this.sort.desc
      },
      set (value) {
        this.setSortDesc(value)
        this.onFetch()
      }
    },
    numero: {
      get () {
        return this.filters.numero
      },
      set (value) {
        this.setFiltersNumero(value)
        this.setPage(1)
        this.onFetch()
      }
    },
    cliente: {
      get () {
        return this.filters.cliente
      },
      set (value) {
        this.setFiltersCliente(value)
        this.setPage(1)
        this.onFetch()
      }
    },
    domicilio: {
      get () {
        return this.filters.domicilio
      },
      set (value) {
        this.setFiltersDomicilio(value)
        this.setPage(1)
        this.onFetch()
      }
    },
    tipoInstalacion: {
      get () {
        return this.filters.tipo_instalacion_id
      },
      set (value) {
        this.setFiltersTipoInstalacionId(value)
        this.setPage(1)
        this.onFetch()
      }
    },
    firmadoFrom: {
      get () {
        return this.filters.firmado.from
      },
      set (value) {
        this.setFiltersFirmadoFrom(value)
        this.setPage(1)
        this.onFetch()
      }
    },
    firmadoTo: {
      get () {
        return this.filters.firmado.to
      },
      set (value) {
        this.setFiltersFirmadoTo(value)
        this.setPage(1)
        this.onFetch()
      }
    },
    vencimientoFrom: {
      get () {
        return this.filters.vencimiento.from
      },
      set (value) {
        this.setFiltersVencimientoFrom(value)
        this.setPage(1)
        this.onFetch()
      }
    },
    vencimientoTo: {
      get () {
        return this.filters.vencimiento.to
      },
      set (value) {
        this.setFiltersVencimientoedTo(value)
        this.setPage(1)
        this.onFetch()
      }
    },
    zona: {
      get () {
        return this.filters.zona_id
      },
      set (value) {
        this.setFiltersZonaId(value)
        this.setPage(1)
        this.onFetch()
      }
    },
    options: {
      get () {
        return this.optionsGetter
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
  },
  methods: {
    ...mapActions({
      onFetch: 'Comodatos/onFetch',
    }),
    ...mapMutations({
      setOptions: 'Comodatos/setOptions',
      setPage: 'Comodatos/setPage',
      setFiltersNumero: 'Comodatos/setFiltersNumero',
      setFiltersCliente: 'Comodatos/setFiltersCliente',
      setFiltersDomicilio: 'Comodatos/setFiltersDomicilio',
      setFiltersTipoInstalacionId: 'Comodatos/setFiltersTipoInstalacionId',
      setFiltersFirmadoFrom: 'Comodatos/setFiltersFirmadoFrom',
      setFiltersFirmadoTo: 'Comodatos/setFiltersFirmadoTo',
      setFiltersVencimientoFrom: 'Comodatos/setFiltersVencimientoFrom',
      setFiltersVencimientoTo: 'Comodatos/setFiltersVencimientoTo',
      setFiltersZonaId: 'Comodatos/setFiltersZonaId',
      setSortBy: 'Comodatos/setSortBy',
      setSortDesc: 'Comodatos/setSortDesc',
    }),
    onOrdenamientoVencimiento (value) {
      this.setSortBy('vencimiento');
      this.setSortDesc(value);
      this.onFetch();
    },
    onOrdenamientoFirmado (value) {
      this.setSortBy('firmado');
      this.setSortDesc(value);
      this.onFetch();
    },
    onClearFilters () {
      this.setFiltersNumero(null);
      this.setFiltersCliente(null);
      this.setFiltersDomicilio(null);
      this.setFiltersTipoInstalacionId(null);
      this.setFiltersFirmadoFrom(null);
      this.setFiltersFirmadoTo(null);
      this.setFiltersVencimientoFrom(null);
      this.setFiltersVencimientoTo(null);
      this.setFiltersZonaId(null);
      this.onFetch()
    },
    onClear () {
      this.setFiltersCliente(null)
      this.onFetch()
    },
    onTime (value) {
      let milis = this.$moment(value)
      return milis.isBefore(this.yesterday) ? milis.format('dddd, DD/MM') : milis.isBefore(this.today) ? 'Ayer' : milis.fromNow()
      // return milis.isBefore(this.yesterday) ? milis.format('dddd, DD/MM') : milis.isBefore(this.today) ? 'Ayer' : milis.format('HH:mm')
    },
    onRefresh() {
      this.onFetch()
    },
    handleKeyboard(event) {
      if(event.key === "F1") {
        event.preventDefault()
        this.$router.push({ name: 'comodatos-form' })
      }
      else if(event.key === "F3") {
        event.preventDefault()
        this.showFilters = !this.showFilters
      }
      else if(event.key === "F4") {
        event.preventDefault()
        this.showOrdering = !this.showOrdering
      }
      else if(event.key === "F5") {
        event.preventDefault()
        this.onFetch()
      }
      // event.preventDefault()
    }
  }
}
</script>
