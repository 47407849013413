<template>
  <div>
    <v-select
      v-model="selectedLocal"
      :items="collection"
      hide-no-data
      item-text="nombre"
      item-value="id"
      :rules="rules"
      prepend-icon="mdi-tanker-truck"
      :label="label"
      :placeholder="placeholder"
      :color="color"
      :readonly="readonly"
      :clearable="clearable"
    >
    <!--
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item
          v-bind="attrs"
          v-on="on"
          dense
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.nombre }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    -->
    </v-select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Number,
    rules: Array,
    readonly: {
      type: Boolean,
      default: false
    },
    list: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      placeholder: 'Seleccione un fabricante',
      label: 'Fabricante'
    }
  },
  created () {
    this.onFetch()
  },
  computed: {
    selectedLocal: {
      get () {
        return this.selected
      },

      set (value) {
        this.$emit('change', value)
      }
    },
    ...mapGetters({
      collection: 'Fabricantes/all'
    })
  },
  methods: {
    ...mapActions({
      onFetch: 'Fabricantes/onFetch'
    }),
    onChange (value) {
      // this.selectedLocal = this.collection.value
    }
  }
}
</script>
