<template>
  <div>
    <v-row>
      <v-col cols="4">
        <my-rehabilitaciones-edit
          v-if="isSelected"
        ></my-rehabilitaciones-edit>
        <my-rehabilitaciones-create
          v-else
        ></my-rehabilitaciones-create>
      </v-col>
      <v-col cols="8">
        <v-sheet>
          <v-container>
            <v-subheader>Recipientes asociados</v-subheader>
            <div v-show="isSelected">
              <my-recipientes-datatable
                :certificado-rehabilitante-id="certificadoRehabilitanteId"
              ></my-recipientes-datatable>
              <v-divider></v-divider>

              <my-rehabilitaciones-recipientes-create
                :certificado-rehabilitante-id="certificadoRehabilitanteId"
                :recipiente-id="recipienteId"
              ></my-rehabilitaciones-recipientes-create>
              <my-recipiente-select
                v-model="recipienteId"
              >
                <template v-slot:top>
                  <v-alert
                    border="left"
                    colored-border
                    color="primary"
                    icon="mdi-information"
                  >
                    Se actualizará la Fecha PH y Vencimiento PH del recipiente seleccionado. Luego, se agrega en <b>Recipientes asociados</b>.
                  </v-alert>
                </template>
              </my-recipiente-select>
            </div>
            <div v-show="!isSelected">
              <v-skeleton-loader
                v-bind="{}"
                type="table"
              ></v-skeleton-loader>
            </div>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import RecipientesSelect from '@/pages/rehabilitaciones_recipientes/Select'
import RecipientesAsociados from '@/pages/rehabilitaciones_recipientes/Datatable'
import RehabilitacionesRecipientesCreate from '@/pages/rehabilitaciones_recipientes/Create'
import RehabilitacionesCreate from '@/pages/rehabilitaciones/Create'
import RehabilitacionesEdit from '@/pages/rehabilitaciones/Edit'
import DatePicker from '@/components/DatePicker'
import FabricanteSelect from '@/pages/fabricantes/Select'

export default {
  components: {
    'my-rehabilitaciones-recipientes-create': RehabilitacionesRecipientesCreate,
    'my-date-picker': DatePicker,
    'my-fabricante-select': FabricanteSelect,
    'my-recipiente-select': RecipientesSelect,
    'my-recipientes-datatable': RecipientesAsociados,
    'my-rehabilitaciones-create': RehabilitacionesCreate,
    'my-rehabilitaciones-edit': RehabilitacionesEdit,
  },
  data () {
    return {
      valid: true,
      certificadoRehabilitanteId: null,
      recipienteId: null,
      attrs: {
        boilerplate: true,
      },
    }
  },
  beforeDestroy() {
    // Acción a realizar antes de que el componente sea destruido
    // Por ejemplo, establecer una variable en Vuex
    this.setFilters({
      ...this.filters,
      excluir: {
        certificado_fabricante_id: null,
        certificado_rehabilitante_id: null
      }
    })
  },
  created () {
    this.onClearEdited() // Rehabilitaciones.onClearEdited
    this.onClearSelected() // Rehabilitaciones.onClearSelected
    if (this.$route.params.id) {
      this.onFind(this.$route.params.id) // Rehabilitaciones.onFind
        .then(response => {
          this.certificadoRehabilitanteId = parseInt(this.$route.params.id)
        })
        this.onClearFilters() // Recipientes.onClearFilters
        this.setFilters({ // Recipientes.setFilters
          ...this.filters,
          excluir: {
            certificado_fabricante_id: null,
            certificado_rehabilitante_id: parseInt(this.$route.params.id)
          }
        })
    }
  },
  computed: {
    ...mapGetters('RehabilitacionesRecipientes', {
      editedRehabilitacionesRecipientes: 'edited',
      loadingRehabilitacionesRecipientes: 'loading'
    }),
    ...mapGetters('Rehabilitaciones', ['edited', 'rules', 'loading']),
    ...mapGetters('RecipientesSelect', ['filters']),
    isSelected () {
      return this.edited.id > 0
    },
  },
  watch: {
    recipienteId (value) {
      // RehabilitacionesRecipientes.setEdited
      this.setEdited({
        ...this.editedRehabilitacionesRecipientes,
        recipiente_id: value
      })
    },
    certificadoRehabilitanteId (value) {
      // RehabilitacionesRecipientes.setEdited
      this.setEdited({
        ...this.editedRehabilitacionesRecipientes,
        certificado_rehabilitante_id: value
      })
    },
  },
  methods: {
    ...mapActions('RehabilitacionesRecipientes', {
      onSave: 'onSave'
    }),
    ...mapActions('Rehabilitaciones', ['onFind', 'onClearEdited', 'onClearSelected']),
    ...mapActions('RecipientesSelect', ['onClearFilters']),
    ...mapMutations('RehabilitacionesRecipientes', ['setEdited']),
    ...mapMutations('RecipientesSelect', ['setFilters']),
  },
}
</script>
