const URL = '/proveedores';

const state = {
  all: [],
  filters: {
    searchText: null,
  },
  loading: false,
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  filters (state) {
    return state.filters
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  loading (state) {
    return state.loading
  },
}

// ACTIONS
const actions = {
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL, {
        params: {
          // itemsPerPage: state.options.itemsPerPage,
          // page: state.options.page,
          // searchText: state.filters.searchText,
          // sortBy: state.options.sortBy[0],
          // sortDesc: state.options.sortDesc[0] ? 1 : 0,
        }
      })
        .then(response => {
          commit('setAll', response.data)
          // commit('setItemsLength', response.data.meta.total)
          // if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
          //   commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
