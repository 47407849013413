<template>
  <div>
    <v-row>
      <v-col cols="4">
        <my-clientes-edit
          subheader="Datos del cliente"
          v-if="isSelected"
        ></my-clientes-edit>
        <my-clientes-create
          subheader="Datos del cliente"
          v-else
        ></my-clientes-create>
      </v-col>
      <v-col cols="8">
        <v-sheet>
          <v-container>
            <v-subheader>Domicilios asociados al cliente</v-subheader>
            <div v-show="clienteId">
              <my-domicilios-datatable
                :cliente-id="clienteId"
              ></my-domicilios-datatable>
            </div>
            <div v-show="!clienteId">
              <v-skeleton-loader
                v-bind="attrs"
                type="table"
              ></v-skeleton-loader>
            </div>
          </v-container>
        </v-sheet>
        <v-divider></v-divider>
        <v-sheet>
          <v-container
            v-if="clienteId && domicilioEdited && domicilioEdited.id"
          >
            <my-domicilio-edit></my-domicilio-edit>
          </v-container>
          <v-container
            v-else-if="clienteId"
          >
            <my-domicilio-create
              :cliente-id="clienteId"
            ></my-domicilio-create>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DomicilioCreate from '@/pages/domicilios/Create'
import DomicilioEdit from '@/pages/domicilios/Edit'
import DomiciliosDatatable from '@/pages/domicilios/Datatable'
import ClientesCreate from '@/pages/clientes/Create'
import ClientesEdit from '@/pages/clientes/Edit'

export default {
  components: {
    'my-domicilio-create': DomicilioCreate,
    'my-domicilio-edit': DomicilioEdit,
    'my-domicilios-datatable': DomiciliosDatatable,
    'my-clientes-create': ClientesCreate,
    'my-clientes-edit': ClientesEdit,
  },
  data () {
    return {
      valid: true,
      attrs: {
        // class: 'mb-6',
        // height: '500px',
        boilerplate: true,
        elevation: 2,
      },
    }
  },
  created () {
    this.onClearEdited()
    this.onClearSelected()
    this.onClearEditedDomicilio()
    this.onClearSelectedDomicilio()
    if (this.$route.params.id)
      this.onFind(this.$route.params.id)
        .then(response => {
          //
        })
  },
  computed: {
    ...mapGetters('Clientes', ['edited', 'loading']),
    ...mapGetters({
      domicilioEdited: 'Domicilios/edited',
      domicilioSelected: 'Domicilios/selected',
      // domicilioFilters: 'Domicilios/filters'
    }),
    // ...mapGetters('Rehabilitaciones', ['edited', 'rules', 'loading']),
    // ...mapGetters('Recipientes', ['filters']),
    isSelected () {
      return this.edited.id != null && this.edited.id != undefined
    },
    clienteId () {
      return this.edited.id
    }
  },
  watch: {
    //
  },
  methods: {
    ...mapActions('Clientes', ['onFind', 'onClearEdited', 'onClearSelected']),
    ...mapActions('Domicilios', { onClearEditedDomicilio: 'onClearEdited', onClearSelectedDomicilio: 'onClearSelected' }),
    ...mapMutations('Recipientes', ['setFilters']),
  },
}
</script>
