<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"      
      >
        <v-container>
          <v-subheader>Certificado de Rehabilitación</v-subheader>
          <v-text-field
            v-model="identificacion"
            placeholder="Ingrese una identificación"
            label="Identificación de certificado"
            :rules="rules.identificacion"
            prepend-icon="mdi-identifier"
            color="primary"
          ></v-text-field>
          <my-date-picker
            v-model="emision"
            label="Fecha de emisión"
          ></my-date-picker>
          <my-proveedor-select
            v-model="proveedor"
          ></my-proveedor-select>
          <v-text-field
            v-model="codigoConstruccion"
            placeholder="Ingrese una descripción"
            label="Codigo de construcción"
            :rules="rules.codigo_construccion"
            prepend-icon="mdi-text-box"
            color="primary"
          ></v-text-field>
          <my-date-picker
            v-model="aprobadoPh"
            label="Fecha PH"
          ></my-date-picker>
          <my-date-picker
            v-model="vencimientoPh"
            label="Fecha de vencimiento PH"
          ></my-date-picker>
          <v-file-input
            accept="image/*"
            label="Documento Escaneado"
          ></v-file-input>
          <v-btn
            color="primary"
            @click="onValidate"
          >
            Registrar
          </v-btn>
        </v-container>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DatePicker from '@/components/DatePicker'
import ProveedorSelect from '@/pages/proveedores/Select'
export default {
  components: {
    'my-date-picker': DatePicker,
    'my-proveedor-select': ProveedorSelect,
  },
  data () {
    return {
      valid: true,
    }
  },
  created () {
    this.onClearEdited()
  },
  computed: {
    ...mapGetters('Rehabilitaciones', ['edited', 'rules'], 'loading'),
    emision: {
      get () {
        return this.edited.emision
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          emision: value
        })
      }
    },
    identificacion: {
      get () {
        return this.edited.identificacion
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          identificacion: value
        })
      }
    },
    codigoConstruccion: {
      get () {
        return this.edited.codigo_construccion
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          codigo_construccion: value
        })
      }
    },
    aprobadoPh: {
      get () {
        return this.edited.aprobado_ph
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          aprobado_ph: value
        })
      }
    },
    vencimientoPh: {
      get () {
        return this.edited.vencimiento_ph
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          vencimiento_ph: value
        })
      }
    },
    proveedor: {
      get () {
        return this.edited.proveedor_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          proveedor_id: value
        })
      }
    },
  },
  methods: {
    ...mapActions('Rehabilitaciones', ['onSave', 'onClearEdited']),
    ...mapMutations('Rehabilitaciones', ['setEdited']),
    onValidate (event) {
      event.preventDefault()
      this.$refs.form.validate()
      if (this.valid) {
        this.onSave().then(() => {
        //   this.$router.push({ name: this.redirect, params: { id: this.cliente }})
        })
      }
    },
  },
}
</script>
