<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="all"
      :footer-props="{
        itemsPerPageText: 'Filas por página',
        itemsPerPageOptions: [10,30,100]
      }"
      :loading="loading"
      :options.sync="optionsLocal"
      :server-items-length="itemsLength"
      :items-per-page="10"
      disable-sort
    >
      <template v-slot:item.id="{ item }">
        <div v-if="item.id">
          <!-- Slot dinámico para botones -->
          <slot name="acciones" :item="item"></slot>
        </div>
      </template>
      <template v-slot:item.fabricante="{ item }">
        <div v-if="item.fabricante">
          {{ item.fabricante.nombre }}
        </div>
      </template>
      <template v-slot:item.tipo="{ item }">
        <div v-if="item.tipo">
          {{ item.tipo.abreviatura }}
        </div>
      </template>
      <template v-slot:item.vencimiento_ph="{ item }">
        <div v-if="item.vencimiento_ph">
          {{ item.vencimiento_ph | moment('MM/YYYY') }}
        </div>
      </template>
      <template v-slot:item.aprobado_ph="{ item }">
        <div v-if="item.aprobado_ph">
          {{ item.aprobado_ph | moment('MM/YYYY') }}
        </div>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="warning" icon="mdi-alert">
          No hay información de recipientes para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  props: {
    updateCollection: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      //
    }
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('FabricacionesRecipientes', ['all', 'headers', 'loading', 'itemsLength', 'options']),
    optionsLocal: {
      get () {
        return this.options
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
  },
  watch: {
    updateCollection (value) {
      if (value) this.onFetch()
    }
  },
  methods: {
    ...mapActions('FabricacionesRecipientes', ['onFetch']),
    ...mapMutations('FabricacionesRecipientes', ['setOptions']),
  }
}
</script>
