<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-sheet>
          <v-subheader>Datos del comodato</v-subheader>
          <my-comodato-edit
            v-if="isSelected"
          ></my-comodato-edit>
          <my-comodato-create
            v-else
          ></my-comodato-create>
        </v-sheet>
      </v-col>
      <v-col cols="8">
        <my-cliente-form
          v-show="paneles.cliente"
        ></my-cliente-form>
        <!--  v-show="paneles.cliente" -->
        <v-divider></v-divider>
        <my-domicilio-form
          v-show="paneles.domicilio"
        ></my-domicilio-form>
        <!--  v-show="paneles.domicilio" -->
        <v-divider></v-divider>
        <!--
        <my-vigencia-form
          v-show="paneles.vigencia"
        ></my-vigencia-form>
        -->
        <v-divider></v-divider>
        <my-instalacion-form
          v-show="paneles.instalacion"
        ></my-instalacion-form>
        <v-divider></v-divider>
        <my-recipientes-form
          v-show="paneles.recipientes"
        ></my-recipientes-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ComodatoCreate from '@/pages/comodatos/Create'
import ComodatoEdit from '@/pages/comodatos/Edit'
// import ComodatoEdit from '@/pages/comodatos/Create'
import ClienteForm from '@/pages/comodatos/ClienteForm'
import DomicilioForm from '@/pages/comodatos/DomicilioForm'
import VigenciaForm from '@/pages/comodatos/VigenciaForm'
import InstalacionForm from '@/pages/comodatos_instalaciones/Form'
import RecipientesForm from '@/pages/comodatos_recipientes/Form'
export default {
  components: {
    'my-cliente-form': ClienteForm,
    'my-domicilio-form': DomicilioForm,
    'my-instalacion-form': InstalacionForm,
    'my-vigencia-form': VigenciaForm,
    'my-recipientes-form': RecipientesForm,
    'my-comodato-edit': ComodatoEdit,
    'my-comodato-create': ComodatoCreate
  },
  data () {
    return {
      valid: {
        cliente: true,
        domicilio: true,
        instalacion: true,
        recipientes: true,
      },
      panel: {
        showCliente: true,
        showDomicilio: true,
        showVigencia: true,
        showInstalacion: true,
        showRecipientes: true
      },
      faq: false,
      stepActual: 1,
      tipoInstalacion: null,
      orden: null,
      fechaInstalacion: null,
    }
  },
  beforeMount() {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy() {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    this.onClearEdited()
    const comodatoId = this.$route.params.id
    if (comodatoId) {
      this.onFind(comodatoId)
        .then(response => {
          this.onFindDomicilio(this.edited.domicilio_id)
          this.onFindCliente(this.edited.cliente_id)
          this.setFiltersInstalaciones({
            ...this.filtersInstalaciones,
            comodato_id: this.edited.id
          })
          this.setFiltersRecipientes({
            ...this.filtersRecipientes,
            comodato_id: this.edited.id
          })
        })
    }
    else {
      this.setPaneles({
        ...this.paneles,
        cliente: true,
        domicilio: true
      })
    }
  },
  computed: {
    ...mapGetters({
      clienteEdited: 'Clientes/selected',
      domicilioEdited: 'Domicilios/edited',
    }),
    ...mapGetters('Comodatos', ['edited', 'paneles']),
    ...mapGetters('Clientes', ['rules', 'loading']),
    cliente () {
      return this.clienteEdited.razon_social || this.clienteEdited.documento || null
    },
    domicilio () {
      return this.domicilioEdited.descripcion || this.domicilioEdited.observaciones || null
    },
    isSelected () {
      return this.edited.id != null && this.edited.id != undefined
    },
  },
  methods: {
    ...mapActions('Clientes', ['onSave', 'onClearEdited', 'onClearSelected']),
    ...mapActions('Comodatos', ['onFind']),
    ...mapActions('Clientes', { onFindCliente: 'onFind' }),
    ...mapActions('Domicilios', { onFindDomicilio: 'onFind' }),
    ...mapMutations('Comodatos', ['setPaneles']),
    // ...mapActions('ComodatosInstalaciones', { onFetchComodatoInstalacion: 'onFind' }),
    // ...mapActions('ComodatosRecipientes', { onFindComodatoRecipiente: 'onFind' }),
    ...mapMutations('ComodatosInstalaciones', {
      setFiltersInstalaciones: 'setFilters'
    }),
    ...mapMutations('ComodatosRecipientes', {
      setFiltersRecipientes: 'setFilters'
    }),
    onCompleteCliente (value) {
      this.valid.cliente = value
      if (value) this.stepActual ++
    },
    onCompleteDomicilio (value) {
      this.valid.domicilio = value
      if (value) this.stepActual ++
    },
    onCompleteInstalacion (value) {
      this.valid.instalacion = value
      if (value) this.stepActual ++
    },
    onCompleteRecipientes (value) {
      this.valid.recipientes = value
      if (value) this.stepActual ++
    },
    onToggleShowCliente (event) {
      this.panel.showCliente = !this.panel.showCliente
      event.preventDefault()
    },
    onToggleShowDomicilio (event) {
      this.panel.showDomicilio = !this.panel.showDomicilio
      event.preventDefault()
    },
    onToggleShowInstalacion (event) {
      this.panel.showInstalacion = !this.panel.showInstalacion
      event.preventDefault()
    },
    onToggleShowRecipientes (event) {
      this.panel.showRecipientes = !this.panel.showRecipientes
      event.preventDefault()
    },
    onToggleShowVigencia (event) {
      this.panel.showVigencia = !this.panel.showVigencia
      event.preventDefault()
    },
    onRedirectComodatosDatatable (event) {

    },
    handleKeyboard(event) {
      if(event.key === "F1") {
        event.preventDefault()
        this.$router.push({ name: 'comodatos-datatable' })
      }
      // else if(event.key === "F3") {
      //   event.preventDefault()
      // }
      // else if(event.key === "F4") {
      //   event.preventDefault()
      // }
      // else if(event.key === "F5") {
      //   event.preventDefault()
      // }
      // event.preventDefault()
    }
  },
}
</script>
