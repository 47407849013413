<template>
  <div>
    <v-sheet>
      <v-container
        v-show="!clienteId"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-check-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Datos del domicilio</v-list-item-title>
        </v-list-item>
        <v-row no-gutters>
          <v-col cols="12">
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item-avatar"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12">
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item-avatar"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item-avatar"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item-avatar"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item-avatar"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              v-bind="attrs"
              type="list-item-avatar"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        v-show="clienteId"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon :color="isSelected ? 'primary' : ''">mdi-check-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Datos del domicilio</v-list-item-title>
        </v-list-item>
        <div v-if="isSelected">
          <!--
          <v-alert
            border="left"
            colored-border
            type="success"
            icon="mdi-account-star"
          >
            A continuación, se muestran los datos del domicilio seleccionado
            También puede optar por registrar un
            <v-btn
              color="primary"
              @click="onDomicilioNuevo"
            >
              Domicilio Nuevo
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-alert>
          -->
          <my-domicilio-editonlive
            :reset="reset"
            :resetValidation="resetValidation"
            @updated="onCreated"
          ></my-domicilio-editonlive>
        </div>
        <div v-else>
        <!--
        <v-alert
          color="primary"
          border="left"
          colored-border
          icon="mdi-account-plus"
        >
          Este formulario se utiliza para registrar un Domicilio Nuevo
        </v-alert>
        -->
          <my-domicilio-createonlive
            :reset="reset"
            :resetValidation="resetValidation"
            @validate="onValidateDomicilio"
          ></my-domicilio-createonlive>
        </div>

        <v-divider class="my-3"></v-divider>
        <my-domicilios-select
          v-model="domicilioId"
        ></my-domicilios-select>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DomiciliosSelect from '@/pages/domicilios/Select'
import DomicilioCreate from '@/pages/domicilios/Create'
import DomicilioEdit from '@/pages/domicilios/Edit'
export default {
  components: {
    'my-domicilio-editonlive': DomicilioEdit,
    'my-domicilio-createonlive': DomicilioCreate,
    'my-domicilios-select': DomiciliosSelect,
  },
  data () {
    return {
      panel: 1,
      reset: false,
      resetValidation: false,
      attrs: {
        // class: 'mb-6',
        boilerplate: true,
        // elevation: 2,
      },
    }
  },
  created () {
    // this.onDefaults()
  },
  computed: {
    ...mapGetters('Comodatos', ['edited']),
    ...mapGetters('Domicilios', ['isSelected', 'filters']),
    clienteId () {
      return this.edited.cliente_id
    },
    numero: {
      get () {
        return this.edited.numero
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          numero: value
        })
      }
    },
    domicilioId: {
      get () {
        return this.edited.domicilio_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          domicilio_id: value
        })
      }
    }
  },
  watch: {
    clienteId (value) {
      this.setFilters({
        ...this.filters,
        cliente_id: value
      })
      if (value) {
        this.onFetch()
      }
      else {
        this.setAll([])
      }
    },
  },
  methods: {
    ...mapActions('Domicilios', ['onFetch', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Domicilios', ['setFilters']),
    ...mapMutations('Comodatos', ['setEdited']),
    onValidateDomicilio (value) {
      if (value) this.$emit('complete', value)
    },
    onCreated () {
      //
    },
    onDomicilioNuevo () {
      this.onClearEdited()
      this.onClearSelected()
    }
  },
}
</script>
