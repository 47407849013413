<template>
  <div>
    <v-dialog
      v-model="drawer"
      width="500"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="drawer = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Menu</v-toolbar-title>
        </v-toolbar>
        <v-list
          nav
          dense
        >
          <v-list-item
            link
          >
            <v-list-item-avatar>
              <v-img :src="'https://ui-avatars.com/api/?bold=true&background=random&name=' + username"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{ username }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :to="{ name: 'sesion-permisos' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-key</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Permisos</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list
          nav
          dense
        >
          <v-list-item
            v-if="$can('menu.zonas')"
            :to="{ name: 'zonas-list' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-terrain</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Zonas</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Tema oscuro
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch
                v-model="$vuetify.theme.dark"
              ></v-switch>
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            @click="onSubmitLogout"
          >
            <v-list-item-icon>
              <v-icon>mdi-power</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Salir
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-app-bar
      :color="color"
      dark
      app
    >
      <v-toolbar-items>
        <!--
        <v-btn
          :to="{ name: 'rehabilitaciones-form' }"
          text
        >
          Rehabilitaciones
        </v-btn>
        -->
        <!--
        <v-btn
          :to="{ name: 'fabricaciones-form' }"
          text
        >
          Fabricaciones
        </v-btn>
        -->
        <v-btn
          text
          :to="{ name: 'recipientes-main' }"
        >
          Recipientes
        </v-btn>
        <v-btn
          text
          :to="{ name: 'clientes-datatable' }"
        >
          Clientes
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text>
          <v-icon dark left>mdi-store-marker</v-icon>
          USH
        </v-btn>
        <v-btn text @click.stop="drawer = !drawer">
          <v-icon dark left>mdi-account</v-icon>
          {{ username }}
        </v-btn>
        <v-btn text @click.stop="drawer = !drawer">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <alertas-view></alertas-view>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
      <v-container>
        <my-forbidden></my-forbidden>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import AlertasView from '@/components/AlertasView'
import PermisosRefresh from '@/components/PermisosRefresh'
import Forbidden from '@/components/Forbidden'
export default {
  components: {
    'alertas-view': AlertasView,
    'my-permiso-refresh': PermisosRefresh,
    'my-forbidden': Forbidden,
  },
  data () {
    return {
      drawer: false,
    }
  },
  created () {
    //
  },
  computed: {
    ...mapGetters({
      user_name: 'Auth/name',
      email: 'Auth/email',
    }),
    username () {
      return this.user_name ? this.user_name.split(' ')[0] : 'Ninguno'
    },
    title () {
      return this.$route.meta.title || 'Sin autorización'
    },
    color () {
      return this.$route.meta.color || 'toolbar'
    },
  },
  methods: {
    ...mapActions({
      onFetch: 'Ordenes/onFetch',
      onLogout: 'Auth/onLogout',
    }),
    ...mapMutations({
      setPage: 'Ordenes/setPage',
    }),
    onSubmitLogout () {
      this.onLogout()
        .then(
          this.$router.push({ name: 'login' })
        )
    },
  }
}
</script>