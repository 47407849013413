import Vue from 'vue'
import Vuex from 'vuex'

import Adjuntos from '@/store/modules/Adjuntos'
import Auth from '@/store/modules/Auth'
import Comodatos from '@/store/modules/Comodatos'
import ComodatosInstalaciones from '@/store/modules/ComodatosInstalaciones'
import ComodatosRecipientes from '@/store/modules/ComodatosRecipientes'
import Clientes from '@/store/modules/Clientes'
import Domicilios from '@/store/modules/Domicilios'
import Fabricaciones from '@/store/modules/Fabricaciones'
import FabricacionesRecipientes from '@/store/modules/FabricacionesRecipientes'
import Fabricantes from '@/store/modules/Fabricantes'
import Proveedores from '@/store/modules/Proveedores'
import Recipientes from '@/store/modules/Recipientes'
import RecipientesTipos from '@/store/modules/RecipientesTipos'
import RecipientesSelect from '@/store/modules/RecipientesSelect'
import Rehabilitaciones from '@/store/modules/Rehabilitaciones'
import RehabilitacionesRecipientes from '@/store/modules/RehabilitacionesRecipientes'
import CertificadosRecipientes from '@/store/modules/CertificadosRecipientes'
import TiposInstalaciones from '@/store/modules/TiposInstalaciones'
import Zonas from '@/store/modules/Zonas'
import ResponseHTTP from '@/store/modules/ResponseHTTP'
// import createLogger from '../../../src/plugins/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Adjuntos,
    Auth,
    Clientes,
    Comodatos,
    ComodatosInstalaciones,
    ComodatosRecipientes,
    Domicilios,
    Fabricaciones,
    FabricacionesRecipientes,
    Fabricantes,
    Proveedores,
    Recipientes,
    RecipientesTipos,
    RecipientesSelect,
    Rehabilitaciones,
    RehabilitacionesRecipientes,
    CertificadosRecipientes,
    TiposInstalaciones,
    Zonas,
    ResponseHTTP,
  },
  // strict: debug,
  // plugins: debug ? [createLogger()] : []
})