<template>
  <div>
    <v-expansion-panels v-model="panelOpen">
      <v-expansion-panel>
        <v-expansion-panel-header color="searchPanel">Domicilios del cliente</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="headers"
            :items="all"
            :footer-props="{
              itemsPerPageText: 'Filas por página'
            }"
            :loading="loading"
            :options.sync="options"
            :server-items-length="itemsLength"
            disable-sort
            @click:row="onSelect"
          >
            <template v-slot:top>
              <v-text-field
                v-model="searchText"
                placeholder="Escriba algún dato del domicilio"
                @keydown.enter="onFetch"
                hint="Enter para buscar domicilio"
              >
                <template v-slot:prepend>
                  <v-btn
                    icon
                    color="primary"
                    @click="onFetch"
                    :disabled="!searchText || searchText.length == 0"
                    :loading="loading"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </template>
            <template v-slot:item.latitud="{ item }">
              {{ item.latitud && item.longitud ? 'Si' : 'No' }}
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" color="error" icon="mdi-alert">
                No se ha encontrado ningún resultado para "{{ searchText }}".
              </v-alert>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="warning" icon="mdi-alert">
                No hay información de domicilios para mostrar.
              </v-alert>
            </template>
            <template v-slot:pageText="props">
              Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      type: Number,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rules: Array,
  },
  data () {
    return {
      valid: false,
      placeholder: 'Busque por Número de documento ó razón social',
      attrs: {
        class: 'text-wrap',
      },
      panelOpen: undefined, // Inicialmente cerrado
      headers: [
        {
          text: 'Posicion',
          value: 'posicion',
          align: 'center'
        },
        {
          text: 'Descripcion',
          value: 'descripcion',
          align: 'center'
        },
        {
          text: 'Observaciones',
          value: 'observaciones',
          align: 'center'
        },
        {
          text: 'Zona',
          value: 'zona.nombre',
          align: 'center'
        },
        {
          text: 'Geolocalizacion',
          value: 'latitud',
          align: 'center'
        },
      ],
    }
  },
  computed: {
    ...mapGetters('Domicilios', ['all', 'loading', 'itemsLength', 'options', 'filters']),
    options: {
      get () {
        return this.optionsGetter
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
    searchText: {
      get () {
        return this.filters.searchText
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          searchText: value
        })
      }
    },
    clienteId: {
      get () {
        return this.filters.cliente_id
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          cliente_id: parseInt(value)
        })
      }
    },
    selectedLocal: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    }
  },
  watch: {
    panelOpen (value) {
      //
    }
  },
  methods: {
    ...mapActions('Domicilios', ['onFetch', 'onClearSelected', 'onClearEdited']),
    ...mapMutations('Domicilios', ['setAll', 'setEdited', 'setSelected', 'setFilters', 'setOptions']),
    onSelect (item) {
      /* Se cargan los datos en la variable Seleccionado para comparar */
      const aux = {
        id: item.id,
        descripcion: item.descripcion,
        observaciones: item.observaciones,
        latitud: item.latitud,
        longitud: item.longitud,
        cliente_id: item.cliente_id,
        posicion: item.posicion,
        zona_id: item.zona_id,
      }
      this.setEdited(aux)
      this.setSelected(aux)
      // Luego de completar la lógica, cierra el panel
      this.panelOpen = undefined;

      this.searchText = null
      this.setAll([])
      this.$emit('change', item.id)
      this.$emit('selected', item)
    },
    onClear () {
      this.searchText = null
      this.onFetch()
    },
    onClearSelect () {
      this.onClearSelected()
      this.onClearEdited()
      this.$emit('change', null)
      this.$emit('selected', null)
      this.setAll([])
    },
    onSubmit () {
      this.onFetch()
    }
  }
}
</script>
