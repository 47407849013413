import Vue from 'vue'
import Router from 'vue-router'
// // LAYOUTS
import MainLayout from '@/pages/layouts/Main'
import Login from '@/pages/Login'
import Forbidden from '@/pages/Forbidden'
import Unauthorized from '@/pages/Unauthorized'
import CredencialesSesion from '@/pages/sesion/Credenciales'
import PermisosSesion from '@/pages/sesion/Permisos'
// CLIENTES
import ClientesDatatable from '@/pages/clientes/Datatable'
import ClientesForm from '@/pages/clientes/Form'
// COMODATOS
import ComodatosList from '@/pages/comodatos/DataTable'
// import ComodatosForm from '@/pages/comodatos/Form'
import ComodatosForm from '@/pages/comodatos/Form'
// FABRICACIONES
import FabricacionesForm from '@/pages/fabricaciones/Form'
// REHABILITACIONES
import RehabilitacionesForm from '@/pages/rehabilitaciones/Form'
// DOMICILIOS
import DomiciliosCreate from '@/pages/domicilios/Create'
import DomiciliosEdit from '@/pages/domicilios/Edit'
import DomiciliosDelete from '@/pages/domicilios/Delete'
// RECIPIENTES
import RecipientesDatatable from '@/pages/recipientes/Main'
// ZONAS
import ZonasList from '@/pages/zonas/List'
import ZonasCreate from '@/pages/zonas/Create'
import ZonasEdit from '@/pages/zonas/Edit'
import ZonasDelete from '@/pages/zonas/Delete'
import ErrorsHttp from '@/pages/Errors'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: Unauthorized,
    },
    {
      path: '/',
      name: 'layout',
      component: MainLayout,
      redirect: 'clientes/datatable',
      children: [
        // ####-####-####-####
        {
          path: 'comodatos/datatable',
          name: 'comodatos-datatable',
          components: {
            default: ComodatosList,
          },
          meta: {
            requiresAuth: true,
            permission: 'clientes.index',
            title: 'Comodatos'
          }
        },
        {
          path: 'comodatos/form/:id?',
          name: 'comodatos-form',
          components: {
            default: ComodatosForm,
          },
          meta: {
            requiresAuth: true,
            permission: 'ordenes.store',
            title: 'Nuevo comodato'
          }
        },
        // ####-####-####-####
        {
          path: 'clientes/datatable',
          name: 'clientes-datatable',
          components: {
            default: ClientesDatatable,
          },
          meta: {
            requiresAuth: true,
            permission: 'clientes.index',
            title: 'Clientes'
          }
        },
        {
          path: 'clientes/form/:id?',
          name: 'clientes-form',
          components: {
            default: ClientesForm,
          },
          meta: {
            requiresAuth: true,
            permission: 'clientes.update'
          }
        },
        // ####-####-####-####
        // {
        //   path: 'domicilios/:id/cliente',
        //   name: 'domicilios-list',
        //   components: {
        //     default: DomiciliosList,
        //   },
        //   meta: {
        //     requiresAuth: true,
        //     permission: 'domicilios.index',
        //     title: 'Domicilios'
        //   }
        // },
        {
          path: 'domicilios/crear/:id/cliente',
          name: 'domicilios-create',
          components: {
            default: DomiciliosCreate,
          },
          meta: {
            requiresAuth: true,
            permission: 'domicilios.store',
            title: 'Nuevo domicilio'
          }
        },
        {
          path: 'domicilios/editar/:id',
          name: 'domicilios-edit',
          components: {
            default: DomiciliosEdit,
          },
          meta: {
            requiresAuth: true,
            permission: 'domicilios.update',
            title: 'Editar domicilio'
          }
        },
        {
          path: 'domicilios/borrar/:id',
          name: 'domicilios-delete',
          components: {
            default: DomiciliosDelete,
          },
          meta: {
            requiresAuth: true,
            permission: 'domicilios.destroy',
            title: 'Borrar domicilio',
            color: 'delete'
          }
        },
        {
          path: 'rehabilitaciones/form/:id?',
          name: 'rehabilitaciones-form',
          components: {
            default: RehabilitacionesForm,
          },
          meta: {
            requiresAuth: true,
            permission: 'certificados_rehabilitantes.store',
            title: 'Certificado de rehabilitación',
          }
        },
        {
          path: 'fabricaciones/form/:id?',
          name: 'fabricaciones-form',
          components: {
            default: FabricacionesForm,
          },
          meta: {
            requiresAuth: true,
            permission: 'certificados_fabricantes.store',
            title: 'Certificado de fabricante',
          }
        },
        {
          path: 'recipientes/main',
          name: 'recipientes-main',
          components: {
            default: RecipientesDatatable,
          },
          meta: {
            requiresAuth: true,
            // permission: 'recipientes.index',
            title: 'Recipientes'
          }
        },
        // ####-####-####-####
        {
          path: 'sesion/credenciales',
          name: 'sesion-credenciales',
          components: {
            default: CredencialesSesion,
          },
          meta: {
            requiresAuth: true,
            title: 'Credenciales',
            color: 'blue'
          }
        },
        {
          path: 'sesion/permisos',
          name: 'sesion-permisos',
          components: {
            default: PermisosSesion,
          },
          meta: {
            requiresAuth: true,
            title: 'Permisos',
            color: 'blue'
          }
        },
        {
          path: '/forbidden',
          name: 'forbidden',
          component: Forbidden,
        },
        // ####-####-####-####
        {
          path: 'zonas',
          name: 'zonas-list',
          components: {
            default: ZonasList,
          },
          meta: {
            requiresAuth: true,
            permission: 'zonas.index',
            title: 'Zonas'
          }
        },
        {
          path: 'zonas/crear',
          name: 'zonas-create',
          components: {
            default: ZonasCreate,
          },
          meta: {
            requiresAuth: true,
            permission: 'zonas.store',
            title: 'Nueva zona'
          }
        },
        {
          path: 'zonas/editar/:id',
          name: 'zonas-edit',
          components: {
            default: ZonasEdit,
          },
          meta: {
            requiresAuth: true,
            permission: 'zonas.update',
            title: 'Editar zona'
          }
        },
        {
          path: 'zonas/borrar/:id',
          name: 'zonas-delete',
          components: {
            default: ZonasDelete,
          },
          meta: {
            requiresAuth: true,
            permission: 'zonas.destroy',
            title: 'Borrar zona',
            color: 'delete'
          }
        },
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (!to.meta.permission) next()
  else {
    let accessToken = window.sessionStorage.getItem('access_token')
    let permissions = JSON.parse(window.sessionStorage.getItem('permissions') || '[]')
    if (permissions.indexOf(to.meta.permission) !== -1) next()
    else if (!accessToken) next({ name: 'login' })
    else next({ name: 'forbidden' })
  }
})

export default router;
