<template>
  <div>
    <v-sheet
      v-show="filters.comodato_id"
    >
      <!--
      <v-container>
        <v-subheader>Datos de los recipientes asociados</v-subheader>
        <v-skeleton-loader
          v-bind="{ boilerplate: true }"
          type="table"
        ></v-skeleton-loader>
      </v-container>
      -->
      <v-container>
        <v-list-item>
          <v-list-item-icon>
            <v-icon :color="isSelected ? 'primary' : ''">mdi-check-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Recipientes instalados</v-list-item-title>
        </v-list-item>
        <my-recipientes-datatable
          :comodato-id="comodatoId"
          :update="sync"
        ></my-recipientes-datatable>
        <v-divider class="my-3"></v-divider>
        <my-recipientes-select
          v-model="recipienteId"
          :comodato-id="comodatoId"
          :update="sync"
        ></my-recipientes-select>
        <!-- Captura el id del recipiente y llama al metodo post de /certificaciones_recipientes -->
        <my-comodato-recipiente-nuevo
          v-model="recipienteId"
        ></my-comodato-recipiente-nuevo>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import RecipientesDatatable from '@/pages/comodatos_recipientes/Datatable'
import RecipientesSelect from '@/pages/comodatos_recipientes/Select'
import RecipientesCreate from '@/pages/comodatos_recipientes/Create'
export default {
  components: {
    'my-recipientes-datatable': RecipientesDatatable,
    'my-recipientes-select': RecipientesSelect,
    'my-comodato-recipiente-nuevo': RecipientesCreate,
  },
  data () {
    return {
      //
      recipienteIdAux: null,
      sync: false,
      isSelected: false,
    }
  },
  created () {
    // this.onDefaults()
  },
  computed: {
    ...mapGetters('ComodatosRecipientes', ['all', 'filters']),
    comodatoId: {
      get () {
        return this.filters.comodato_id
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          comodato_id: value
        })
      }
    },
    recipienteId: {
      get () {
        return this.recipienteIdAux
      },
      set (value) {
        this.recipienteIdAux = value
      }
    }
  },
  watch: {
    recipienteId (value) {
      console.log(value)
      this.sync = value ? true : false
    },
    all (value) {
      if (value && value.length > 0) this.isSelected = true
      else this.isSelected = false
    }
  },
  methods: {
    ...mapActions('ComodatosRecipientes', ['onClearEdited', 'onClearSelected']),
    ...mapMutations('ComodatosRecipientes', ['setEdited', 'setFilters']),
    onValidate (value) {
      if (value) this.$emit('complete', value)
    },
    onDelete (item) {
      // alert('delete')
    }
  },
}
</script>
